<template>
  <div class="conditional-link-card">
    <div class="flex flex-column">
      <div class="conditional-link-header">
        <ButtonPlay
          type="clear"
          @click="false"
          icon="menu"
          icon-size="20"
          dense
          class="drag-handle"
        />
        <span class="conditional-link-header-title">{{ title }}</span>
        <ButtonPlay
          type="clear"
          @click="$emit('remove')"
          icon="trash"
          icon-size="20"
          dense
        />
      </div>
    </div>

    <div class="conditional-link-form d-flex flex-column">
      <div class="row">
        <div class="col-2">
          <label>{{$t('members.modal_conditional_links.labels.icon')}}</label>
          <InputIcon
            v-model="link.icon"
            hide-label="true"
            name="icon"
          />
        </div>
        <div class="col-5 m-0 p-0">
          <InputFormPlay
            v-model="link.label"
            :label="$t('members.modal_conditional_links.labels.name')"
            :placeholder="$t('members.modal_conditional_links.placeholders.name')"
            type="text"
            class="edit-name"
            name="nome"
          />
        </div>
        <div class="col-5">
          <InputFormPlay
            v-model="link.url"
            :label="$t('members.modal_conditional_links.labels.url')"
            :placeholder="$t('members.modal_conditional_links.placeholders.url')"
            type="url"
            class="edit-name"
            name="url"
          />
        </div>
      </div>

      <div class="row align-items-baseline">
        <div class="col">
          <div class="d-flex">
            <toggle-button
              v-model="link.newtab"
              :sync="true"
              class="mr-3"
            />
            <label>{{ $t('members.modal_conditional_links.labels.newtab') }}</label>
          </div>

          <div class="d-flex align-items-start">
            <toggle-button
              v-model="link.conditional"
              :sync="true"
              class="mr-3"
            />
            <label>{{ $t('members.modal_conditional_links.labels.conditional') }}</label>
            <IconSax
              name="info-circle"
              v-b-tooltip.hover
              :title="$t('members.modal_conditional_links.tooltips.conditional')"
              class="ml-2"
            />
          </div>
        </div>
        <div class="col-auto">
          <ButtonPlay
            v-if="link.icon || link.label || link.url || link.conditional || link.newtab"
            type="clear"
            color="var(--maincolor)"
            :text="$t('members.modal_conditional_links.buttons.clear')"
            @click="clearFields"
          />
        </div>
      </div>

      <div class="d-flex flex-column">
        <b-form-group
          v-if="link.conditional"
          :label="$t('members.modal_conditional_links.labels.liberation_type')"
          label-for="course_class_ids">
          <SelectPlay
            v-model="link.type"
            name="type"
            :placeholder="$t('members.modal_conditional_links.placeholders.liberation_type')"
            :options="type_options"
            v-validate="{ required: true }"
            open-direction="top"
          />
        </b-form-group>

        <b-form-group
          key="course_class_ids"
          v-if="link.conditional && link.type === 'course_class'"
          :label="$t('members.modal_conditional_links.labels.liberation_classes')"
          label-for="course_class_ids">
          <MultiselectPlay
            v-model="selected_course_classes"
            id="course_class_ids"
            name="course_class_ids"
            label="label"
            track-by="id"
            :placeholder="$t('members.modal_conditional_links.placeholders.liberation_classes')"
            :options="course_class_options"
            :multiple="true"
            open-direction="top"
          />
        </b-form-group>

        <b-form-group
          key="signature_ids"
          v-if="link.conditional && link.type === 'signature'"
          :label="$t('members.modal_conditional_links.labels.liberation_signatures')"
          label-for="signature_ids">
          <MultiselectPlay
            v-model="selected_signatures"
            id="signature_ids"
            name="signature_ids"
            label="label"
            track-by="id"
            :placeholder="$t('members.modal_conditional_links.placeholders.liberation_signatures')"
            :options="signature_options"
            :multiple="true"
            open-direction="top"
          />
        </b-form-group>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonPlay from "@/components/common/ButtonPlay"
import { mapActions, mapGetters } from "vuex";
import InputIcon from "@/components/form/InputIcon.vue";
import SelectPlay from "@/components/common/SelectPlay.vue";
import ClassService from "@/services/resources/ClassService";
import SignatureService from "@/services/resources/SignatureService";
import InputFormPlay from "@/components/common/InputFormPlay.vue";
import {ConditionalLinkAdapter} from "@/classes/ConditionalLinkAdapter";
import MultiselectPlay from "@/components/common/MultiselectPlay.vue";

const serviceClass = ClassService.build();
const serviceSignature = SignatureService.build();

export default {
  components: {
    MultiselectPlay,
    InputFormPlay,
    SelectPlay,
    InputIcon,
    ButtonPlay,
  },
  props: {
    link: {
      type: ConditionalLinkAdapter,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      link_clone: null,
      course_class_options: [],
      selected_course_classes: [],
      signature_options: [],
      selected_signatures: [],
      type_options: [
        { id: "course_class", label: this.$t('members.modal_create.labels.class') },
        { id: "signature", label: this.$t('members.modal_create.labels.signature') },
      ],
    }
  },
  watch: {
    selected_course_classes(val) {
      this.link.course_class_ids = val.map(i => i.id)
    },
    selected_signatures(val) {
      this.link.signature_ids = val.map(i => i.id)
    },
    link: {
      deep: true,
      handler(val) {
        /**
         * Check for conditional change
         */
        if (val.conditional !== this.link_clone?.conditional) {
          this.clearConditionalSelection()
          this.link.type = null
        }

        /**
         * Check for conditional type changes
         */
        if (val.type !== this.link_clone.type) {
          this.clearConditionalSelection()
          this.getConditionalsOptions()
        }

        this.clone(val)
      }
    },
  },
  computed: {
    ...mapGetters({
      getSettings: "config/getSettings",
      actionClassLoadSelect: 'courseClass/actionLoadSelect',
      actionSignatureLoadSelect: 'signatures/actionLoadSelect',
    })
  },
  methods: {
    ...mapActions({
      actionSaveSettings: 'config/actionSaveSettings'
    }),

    /**
     * Create a clone of Link structure to detect changes
     */
    clone(link) {
      // eslint-disable-next-line no-undef
      this.link_clone = structuredClone(link)
    },

    /**
     * Clear all fields
     */
    clearFields() {
      this.link.icon = null
      this.link.label = null;
      this.link.url = null;
      this.link.newtab = false;
      this.link.conditional = false;
      this.link.type = null;
    },

    /**
     * Clear selected courses and signatures
     */
    clearConditionalSelection() {
      this.selected_course_classes = []
      this.selected_signatures = []
    },

    /**
     * Get available Classes or Signatures
     * @returns {Promise<void>}
     */
    async getConditionalsOptions() {
      if (!this.link.type) {
        throw 'Invalid option'
      }

      if (this.link.type === 'course_class') {
        await this.fetchClasses()
      } else {
        await this.fetchSignatures()
      }
    },

    /**
     * Fetch available Classes
     * @returns {Promise<void>}
     */
    async fetchClasses() {
      this.$root.$emit("loadOn");
      try {
        this.course_class_options = await serviceClass.read("/select")
      } finally {
        this.$root.$emit("loadOff");
      }
    },

    /**
     * Load stored course classes
     */
    loadStoredCourseClasses() {
      const classes = this.link?.course_class_ids
      if (classes.length > 0) {
        const selectedClasses = this.course_class_options.filter(v => classes.includes(v.id))
        this.selected_course_classes.push(...selectedClasses)
      }
    },

    /**
     * Fetch available Signatures
     * @returns {Promise<void>}
     */
    async fetchSignatures() {
      this.$root.$emit("loadOn")
      try {
        this.signature_options = await serviceSignature.read("/select")
      } finally {
        this.$root.$emit("loadOff")
      }
    },

    /**
     * Load stored signatures
     */
    loadStoredSignatures() {
      const signatures = this.link?.signature_ids
      if (signatures.length > 0) {
        const selectedSignatures = this.signature_options.filter(v => signatures.includes(v.id))
        this.selected_signatures.push(...selectedSignatures)
      }
    },
  },
  mounted() {
    this.clone(this.link)
    this.getConditionalsOptions().then(() => {
      if (!this.link?.type) {
        return;
      }

      if (this.link.type === "course_class") {
        this.loadStoredCourseClasses()
      } else {
        this.loadStoredSignatures()
      }
    })
  }
}

</script>
<style lang="scss">
.conditional-link-card {
  background: #262626;
  border-radius: 16px;

  .multiselect__tags, .input-play, .input-icon {
    border: 1px solid var(--neutral-gray-500) !important;
  }

  .conditional-link-header {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    background: #404043;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .conditional-link-header .conditional-link-header-title {
    flex: 1 1 auto;
    font-size: 16px;
    font-weight: 600;
    color: white;
  }

  .conditional-link-form {
    padding: 24px;
    gap: 16px;
  }

  .conditional-link-form .input-icon {
    width: 100%;
    border-radius: 8px;
    height: 48px;
    min-width: unset;
  }

  .drag-handle {
    cursor: pointer;
  }
}

[data-theme="light"] {
  .conditional-link-card {
    background-color: var(--neutral-gray-80);
    color: var(--neutral-gray-900);

    .conditional-link-header {
      background-color: var(--neutral-gray-200);

      .conditional-link-header-title {
        color: var(--neutral-gray-900);
      }
    }
  }
}
</style>
